import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Werkzeuge from "../assets/images/handwerker.png"

const Versprechen = () => {
  return (
    <Layout>
      <Seo title="React Shopify Gatsby js Wordpress Krefeld" />
      <div class="bg-white xl:pb-12">
        <div class="text-gray-500 px-6 lg:px-8 pt-12 pb-10 lg:pt-20 ">
          <div class="max-w-screen-xl mx-auto">
            <h2 class="text-2xl sm:text-3xl md:text-4xl text-MedienwerkstattDarkBlue font-medium text-center ">
              Unsere Werkzeuge für Ihre Projekte
            </h2>
            <p class="text-xl lg:text-2xl text-gray-500 mt-2 text-center">
              <div class="max-w-screen-xl mx-auto">
                <img src={Werkzeuge} alt="Kontakt Medienwerkstatt Krefeld" />
              </div>
            </p>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-12 max-w-screen-xl mx-auto mt-12 lg:mt-20 space-y-12 md:space-y-0">
            <div class="text-left flex space-x-6">
              <div class="bg-MedienwerkstattDarkBlue  text-white rounded-full w-12 h-12 md:w-16 md:h-16 flex items-center justify-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke=" MedienwerkstattDarkBlue"
                  class="w-8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                  />
                </svg>
              </div>
              <div class="flex-1">
                <h3 class="font-semibold text-lg mb-2">Wordpress</h3>
                <p>
                  Wordpress ist die ideale Lösung für den Kunden der seine neue
                  Webseite schnell und kostengünstig braucht.
                </p>
              </div>
            </div>
            <div class="text-left flex space-x-6">
              <div class="bg-MedienwerkstattYellow text-white rounded-full w-12 h-12 md:w-16 md:h-16 flex items-center justify-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              </div>
              <div class="flex-1">
                <h3 class="font-semibold text-lg mb-2">Shopify/Woocommerce</h3>
                <p>
                  Wenn Sie einen Onlineshop benötigen sind unsere Werkezeuge
                  Gatsby js und Shopify oder Gatsby js und Wordpress. Die
                  jeweiligen Vor- und Nachteilen klären wir im persönlichen
                  Beratungsgespräch und Sie entscheiden dann, welcher Weg der
                  Beste für Sie ist.
                </p>
              </div>
            </div>
            <div class="text-left flex space-x-6">
              <div class="bg-MedienwerkstattGreen text-white rounded-full w-12 h-12 md:w-16 md:h-16 flex items-center justify-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
                  />
                </svg>
              </div>
              <div class="flex-1">
                <h3 class="font-semibold text-lg mb-2">React Native </h3>
                <p>
                  Sie brauchen eine App? Wir programmieren Ihre Idee. Hier sind
                  unsere Werkzeuge React Native oder wieder unser
                  Lieblingserkzeug Gatsby js.
                </p>
              </div>
            </div>
            <div class="text-left flex space-x-6">
              <div class="bg-MedienwerkstattBlue text-white rounded-full w-12 h-12 md:w-16 md:h-16 flex items-center justify-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </div>
              <div class="flex-1">
                <h3 class="font-semibold text-lg mb-2">Projektkosten</h3>
                <p>
                  Unsere Angebote starten bei 1000 Euro (+ MwSt), natürlich ist
                  auch nach oben fast alle offen. Unsere Angebote sind aber fair
                  und immer nachvollziehbar.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Versprechen
